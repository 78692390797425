@use "@/styles/abstracts" as *;

.list {
	list-style: none;
	margin: 0;
	padding: 0;
	display: grid;
	gap: rem(12);
	@include breakpoint($md) {
		gap: rem(16);
	}
	@include breakpoint($lg) {
		gap: rem(24);
	}

	&__item {
		display: flex;
		align-items: flex-start;
		gap: rem(12);
		@include breakpoint($md) {
			gap: rem(16);
		}
		@include breakpoint($lg) {
			gap: rem(24);
		}
	}

	& &__icon {
		flex: none;
		--size: #{rem(48)};
		display: inline-flex;
		justify-content: center;
		width: var(--size);
		height: auto;
		color: var(--theme--button-fill-accent);

		// > * {
		// 	width: 100%;
		// 	height: auto;
		// }

		&--circle {
			height: var(--size);
			color: var(--theme--background);
			background-color: var(--theme--button-fill-accent);
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100vw;
			> * {
				font-size: calc(var(--size) * 0.5);
				height: 100%;
			}
		}

		&--default {
			height: calc(var(--size) * 0.5);
		}
	}

	&__content {
		display: grid;
		gap: rem(8);
	}

	&__title {
		@include body-1;
		font-weight: $fw-bold;
		margin: 0;
	}

	&__text {
		@include body-2;
		margin: 0;
		word-break: break-word;
	}
}
