@use "@/styles/abstracts" as *;

.cta-block {
	$block: &;
	padding-left: calculated-container-margin($container-std);
	padding-right: calculated-container-margin($container-std);
	padding-top: rem(64);
	padding-bottom: rem(64);

	@include breakpoint($md) {
		padding-top: rem(80);
		padding-bottom: rem(80);
	}
	@include breakpoint($lg) {
		padding-top: rem(96);
		padding-bottom: rem(96);
	}

	&--dawn {
		background-color: $color-purple--dark;
		#{$block}__inner {
			@include theme-dawn;
			background-color: var(--theme--background);
			color: var(--theme--text-color);
		}
	}
	&--dusk {
		background-color: $color-orange--light;
		#{$block}__inner {
			@include theme-dusk;
			background-color: var(--theme--background);
			color: var(--theme--text-color);
		}
	}

	&--midnight {
		background-color: $color-orange--light;
		#{$block}__inner {
			@include theme-midnight;
			background-color: var(--theme--background);
			color: var(--theme--text-color);
		}
	}

	&__inner {
		overflow: hidden;
		background-color: $color-orange--light;
		border-radius: 24px;
		display: flex;
		gap: rem(16);
		flex-direction: column;
		@include breakpoint($md) {
			flex-direction: row;
		}
	}

	&__content {
		flex: 1.5 1;
		display: grid;
		padding: rem(32);
		gap: rem(16);
		justify-items: flex-start;

		@include breakpoint($md) {
			padding: rem(40);
		}

		@include breakpoint($lg) {
			gap: rem(24);
			padding: rem(48);
		}

		#{$block}--no-image & {
			text-align: center;
			justify-items: center;
			max-width: rem(400);
			box-sizing: content-box;
			margin: 0 auto;
		}
	}

	&__heading {
		@include h4;
		margin: 0;
	}

	&__body {
		@include body-2;
		margin: 0;
	}

	&__image {
		flex: 1 1;

		mask-image: url("/heading-mask-mobile.svg");
		mask-size: cover;
		position: relative;
		aspect-ratio: 1/1;
		width: 100%;

		@include breakpoint($md) {
			mask-image: url("/heading-mask.svg");
			width: auto;
			aspect-ratio: auto;
		}

		img {
			position: absolute;
			height: 100%;
			width: 100%;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			object-fit: cover;
			color: transparent;
		}
	}
}
