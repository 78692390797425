@use "@/styles/abstracts" as *;
.content {
	$block: &;
	padding: rem(60) 0;

	@include breakpoint($md) {
		padding: rem(80) 0;
	}

	@include breakpoint($lg) {
		padding: rem(96) 0;
	}

	&--dawn {
		@include theme-dawn;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
	}
	&--dusk {
		@include theme-dusk;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
	}
	&--midnight {
		@include theme-midnight;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
	}

	&--align-center {
		text-align: center;
	}

	&__wrapper {
		@include container($container-std);
	}

	&__inner {
		display: grid;
		gap: rem(16);
		@include breakpoint($lg) {
			gap: rem(24);
		}

		#{$block}--align-left & {
			justify-items: flex-start;
		}
		#{$block}--align-center & {
			justify-items: center;
		}
		#{$block}--align-right & {
			justify-items: flex-start;
		}
	}

	&__header {
		display: grid;
		justify-items: inherit;
		@include breakpoint($md) {
			display: flex;
			align-items: baseline;
			justify-content: center;
		}
	}

	&__flourish {
		order: -1;
		margin-bottom: rem(16);
		width: rem(75);
		height: rem(75);
		@include breakpoint($md) {
			order: 0;
			margin: 0;
			width: rem(90);
			height: rem(90);
			// margin-right: rem(-90);
		}

		@include breakpoint($lg) {
			width: rem(110);
			height: rem(110);
			// margin-right: rem(-110);
		}
	}

	&__heading {
		@include h3;
		margin: 0;
	}

	&__body {
		@include body-2;
	}
}
