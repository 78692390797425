@use "@/styles/abstracts" as *;

.grid-block {
	padding: rem(60) 0;

	@include breakpoint($md) {
		padding: rem(80) 0;
	}

	@include breakpoint($lg) {
		padding: rem(96) 0;
	}

	&--dawn {
		@include theme-dawn;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
	}
	&--dusk {
		@include theme-dusk;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
	}
	&--midnight {
		@include theme-midnight;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
	}

	&__wrapper {
		@include container($container-std);
		display: grid;
		row-gap: rem(24);

		@include breakpoint($md) {
			row-gap: rem(32);
		}
		@include breakpoint($lg) {
			row-gap: rem(40);
		}
	}

	&__intro {
		text-align: center;
		display: grid;
		justify-items: center;
		gap: rem(12);

		@include breakpoint($md) {
			gap: rem(16);
		}

		@include breakpoint($md) {
			gap: rem(24);
		}
	}

	&__heading {
		position: relative;
		@include h3;
		margin: 0;
	}

	&__image {
		position: absolute;
		top: rem(-20);
		right: rem(40);
		transform: translate(100%, -40%);

		width: rem(96);
		height: rem(96);

		@include breakpoint($md) {
			width: rem(128);
			height: rem(128);
		}

		@include breakpoint($lg) {
			width: rem(160);
			height: rem(160);
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__body {
		@include body-1;
	}

	&__grid {
		grid-column: 1/-1;
		display: grid;
		row-gap: rem(24);

		@include breakpoint($md, $lg - 1px) {
			row-gap: rem(32);
			column-gap: rem(56);
			grid-template-columns: repeat(4, 1fr);

			> * {
				grid-column: span 2;

				&:nth-child(odd):last-child {
					grid-column: 2 / span 2;
				}
			}
		}

		@include breakpoint($lg) {
			row-gap: rem(40);
			column-gap: rem(64);
			grid-template-columns: repeat(6, 1fr);
			> * {
				grid-column: span 2;

				&:nth-child(3n + 1):last-child {
					grid-column: 3 / span 2;
				}
				&:nth-child(3n + 1):nth-last-child(2) {
					grid-column: 2 / span 2;
				}
			}
		}
	}
}
