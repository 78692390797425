@use "@/styles/abstracts" as *;

.card-team-member {
	position: relative;
	background: var(--theme--card-bg, #{$color-white});

	padding: rem(24);
	border-radius: 24px 24px 64px 24px;
	text-align: center;

	@include breakpoint($md) {
		padding: rem(32);
	}

	@include breakpoint($lg) {
		padding: rem(40);
	}

	&__inner {
		@include theme-dawn;
		color: var(--theme--text-color);
		display: grid;
		gap: rem(16);
		justify-items: center;
	}

	&__image {
		justify-self: center;
		width: rem(140);
		height: rem(140);
		margin-bottom: rem(16);

		@include breakpoint($md) {
			width: rem(160);
			height: rem(160);
		}

		@include breakpoint($lg) {
			width: rem(180);
			height: rem(180);
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 50%;
		}
	}

	&__name {
		@include h4;
		margin: 0;
	}

	&__role {
		@include pre-heading;
		margin: 0;
	}

	&__bio {
		margin: 0;
		@include body-2;
	}
}
