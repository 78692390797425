@use "@/styles/abstracts" as *;

.content-list {
	$block: &;
	padding: rem(60) 0;

	@include breakpoint($md) {
		padding: rem(80) 0;
	}

	@include breakpoint($lg) {
		padding: rem(96) 0;
	}

	&--dawn {
		@include theme-dawn;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
	}
	&--dusk {
		@include theme-dusk;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
	}
	&--midnight {
		@include theme-midnight;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
	}

	&__inner {
		@include container($container-std);
		display: grid;
		gap: rem(40);
		align-items: flex-start;
		@include breakpoint($md) {
			gap: rem(48);
		}
		@include breakpoint($lg) {
			gap: rem(56);
			grid-template-columns: 1fr 1fr;
		}
	}

	&__content {
		display: grid;
		justify-items: flex-start;
		gap: rem(12);
		@include breakpoint($md) {
			gap: rem(16);
		}
		@include breakpoint($md) {
			gap: rem(24);
		}
	}

	&__heading {
		@include h3;
		margin: 0;
	}

	&__content-inner {
		display: grid;
		gap: rem(8);
	}

	&__subtitle {
		@include body-1;
		font-weight: $fw-bold;
	}

	&__body {
		@include body-2;
		margin: 0;
	}
}
