@use "@/styles/abstracts" as *;

.card {
	position: relative;
	align-self: flex-start;
	background: var(--theme--card-bg, #{$color-white});

	padding: rem(24);
	border-radius: 24px 24px 64px 24px;

	@include breakpoint($md) {
		padding: rem(32);
	}

	@include breakpoint($lg) {
		padding: rem(40);
	}

	&--icon {
		margin-top: rem(48);
	}

	&__inner {
		@include theme-dawn;
		color: var(--theme--text-color);
		display: grid;
		gap: rem(16);
		justify-items: flex-start;
	}

	&__image {
		justify-self: center;
		width: rem(140);
		height: rem(140);
		margin-bottom: rem(16);

		@include breakpoint($md) {
			width: rem(160);
			height: rem(160);
		}

		@include breakpoint($lg) {
			width: rem(180);
			height: rem(180);
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 50%;
		}
	}

	&__icon {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, -50%);
		width: rem(40);
		height: rem(40);

		@include breakpoint($md) {
			width: rem(48);
			height: rem(48);
		}

		@include breakpoint($lg) {
			width: rem(64);
			height: rem(64);
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__title {
		@include h5;
		margin: 0;
	}

	&__body {
		margin: 0;
		@include body-2;
	}
}
