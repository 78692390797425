@use "@/styles/abstracts" as *;

.quote-block {
	&--dawn {
		@include theme-dawn;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
	}

	&--dusk {
		@include theme-dusk;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
	}

	&--midnight {
		@include theme-midnight;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
	}

	display: grid;
	grid-template-areas:
		"heading heading heading"
		"track track track"
		"prev dots next";
	grid-template-columns: auto 1fr auto;

	text-align: center;
	column-gap: rem(12);
	--row-gap: #{rem(24)};
	padding-block: rem(64);
	padding-inline: calculated-container-margin($container-std);

	@include breakpoint($md) {
		column-gap: rem(16);
		--row-gap: #{rem(32)};
		padding-block: rem(80);
		grid-template-areas:
			"heading heading heading"
			"prev track next"
			"dots dots dots";
		grid-template-columns: auto 1fr auto;
	}

	@include breakpoint($lg) {
		column-gap: rem(24);
		--row-gap: #{rem(50)};
		padding-block: rem(96);
		grid-template-areas:
			". heading heading heading ."
			". prev track next ."
			". dots dots dots .";
		grid-template-columns: 2fr auto 8fr auto 2fr;
	}

	&__heading {
		grid-area: heading;
		@include h3;
		margin: 0;
		margin-bottom: var(--row-gap);
	}

	&__inner {
		grid-area: track;
		display: grid;
		align-items: center;
		gap: rem(24);
	}

	&__track-wrapper {
		overflow: hidden;
	}

	&__track {
		display: grid;
		align-items: center;
		grid-auto-flow: column;
		grid-auto-columns: 100%;
	}

	& &__button {
		align-self: center;
		&-inner {
			padding-left: rem(30);
			padding-right: rem(30);
			&:not(:hover):not(:focus-within) {
				border-radius: rem(28);
			}
		}
		svg {
			height: rem(24);
			width: rem(16);
		}
		&--next {
			grid-area: next;
			svg {
				transform: scaleX(-1);
			}
		}
		&--prev {
			grid-area: prev;
		}
	}

	&__dots {
		grid-area: dots;
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: flex;
		justify-content: center;
		gap: rem(10);
		margin-top: var(--row-gap);
	}

	&__dot {
		border: none;
		appearance: none;
		margin: 0;
		padding: 0;
		width: rem(10);
		height: rem(10);
		border-radius: 100vw;
		cursor: pointer;
		position: relative;
		color: var(--theme--button-fill-accent);
		background: var(--theme--button-fill-accent);
		@include link-cover(rem(8));
		opacity: 0.5;

		&--active {
			opacity: 1;
		}
	}
}

.quote-item {
	width: 100%;
	margin: 0;

	&__body {
		@include h5;
		margin: 0;
	}

	&__caption {
		@include body-2;
		margin: rem(24) 0 0;
	}
}
