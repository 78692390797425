@use "@/styles/abstracts" as *;

.picture {
	// display: block;
	line-height: 0;
	max-width: 100%;

	img {
		object-fit: cover;
	}

	&--responsive {
		img {
			width: 100%;
			height: auto;
		}
	}
}
