@use "@/styles/abstracts" as *;

.content-image {
	$block: &;
	padding: rem(60) 0;

	@include breakpoint($md) {
		padding: rem(80) 0;
	}

	@include breakpoint($lg) {
		padding: rem(96) 0;
	}

	&--dawn {
		@include theme-dawn;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
	}
	&--dusk {
		@include theme-dusk;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
	}
	&--midnight {
		@include theme-midnight;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
	}

	&--align-left {
		@include breakpoint(0, $md - 1px) {
			#{$block}__image {
				order: -1;
			}
		}
	}

	&__inner {
		@include container($container-std);
		display: grid;
		gap: rem(40);
		flex-direction: row-reverse;
		grid-template-areas: "image" "content";

		#{$block}--align-right & {
			@include breakpoint($lg) {
				grid-template-areas: "image content";
			}
		}
		#{$block}--align-left & {
			@include breakpoint($lg) {
				grid-template-areas: "content image";
			}
		}

		// --container-gutter: #{rem(48)}; // Old site's padding was hideous, let's just not do that

		@include breakpoint($md) {
			// --container-gutter: #{rem(124)}; // Old site's padding was hideous, let's just not do that
			gap: rem(48);
		}
		@include breakpoint($lg) {
			// --container-gutter: #{rem(200)}; // Old site's padding was hideous, let's just not do that
			gap: rem(56);
			grid-template-columns: 1fr 1fr;
		}
	}

	&__content {
		grid-area: content;
		align-self: center;
		display: grid;
		justify-items: flex-start;
		gap: rem(16);
		@include breakpoint($md) {
			gap: rem(24);
		}
	}

	&__heading {
		@include h3;
		margin: 0;
	}

	&__body {
		@include body-2;
		margin: 0;
	}

	& &__image {
		grid-area: image;
		width: 100%;
		max-width: 55%;

		@include breakpoint($lg) {
			max-width: rem(480);
		}
		img {
			width: 100%;
			height: auto;
			border-radius: 100vw;
		}
	}
}
